<template>
	<div>
		<van-steps :active="step_active" active-icon="success" active-color="#ff976a">
			<van-step>{{translate('history_options')}}</van-step>
			<van-step>{{translate('history_withdrawal_amount')}}</van-step>
			<van-step>{{translate('history_accounts_receivable')}}</van-step>
			<van-step>{{translate('history_moderated')}}</van-step>
		</van-steps>
		<div
			v-if="kaika"
			style="padding: 15px 10px;
				background: #fff;
				margin: 10px;
				border-radius: 5px;
				display:flex;align-items: center"
			@click="goSpeedPay()">
			<p style="color: #666;padding-left: 15px;font-weight: bold">SpeedPay开卡申请</p>
		</div>
		<div v-for="(item, index) in currencys" :key="index" 
			style="padding: 15px 10px;
				background: #fff;
				margin: 10px;
				border-radius: 5px;
				display:flex;align-items: center"
			@click="goAmount(index)">

			<van-image :src="item.image" width="60" height="60"></van-image>
			<p style="color: #666;padding-left: 15px;font-weight: bold">{{translate('history_extract')}}{{ item.name }}</p>
		</div>

		<div style="padding: 15px 10px;background: #fff;margin: 10px;border-radius: 5px;display:flex;align-items: center"
			@click="goOther()">
			<van-image :src="require('@/assets/images/other.png')" width="60" height="60"></van-image>

			<p style="color: #666;padding-left: 15px;font-weight: bold">其它业务</p>
		</div>

		<div class="common_block">
			<p style="text-align: center;color: blue;" @click="goHistory">{{translate('history_withdrawal_record')}}</p>
		</div>

		<div class="common_block">
			<h5 style="margin: 30px 0 10px 0">{{translate('history_withdrawal_tips')}}</h5>
			<div class="description" v-html="withdraw_tip"></div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'withdraw_index',
		data() {
			return {
				kaika:false,
				step_active: 0,

				token: "",
				withdraw_tip: "",

				currencys: []
			}
		},
		mounted() {
			if (this.$route.query.token == "" || this.$route.query.token == undefined) {
				this.$router.replace('/nologin')
			} else {
				this.token = this.$route.query.token

				this.$axios({
					method: 'post',
					url: 'cash_withdraw/currency', 
					data: {
						token: this.token
					}
				}).then ((res) => {
					if (res.success) {
						this.currencys = res.data.currencys
						this.withdraw_tip = res.data.withdraw_tip
					}
				})
			}
			this.getSpeedpayCard();
		},
		methods: {
			goSpeedPay(){
				this.$router.push('/speedpay/index?token=' + this.token)
			},
			goHistory() {
				this.$router.push('/withdraw/history?token=' + this.token)
			},
			goAmount(index) {

				window.sessionStorage.setItem('c', this.currencys[index]['currency_id'])
				window.sessionStorage.setItem('c_name', this.currencys[index]['name'])

				this.$router.push('/withdraw/amount?token=' + this.token)
			},
			goOther() {
				let index = 0
				
				window.sessionStorage.setItem('c', this.currencys[index]['currency_id'])
				window.sessionStorage.setItem('c_name', this.currencys[index]['name'])
				this.$router.push('/withdraw/other?token=' + this.token)
			},
			getSpeedpayCard(){
				this.$axios({
					method: 'post',
					url: 'cash_withdraw/getSpeedpayCard', 
					data:{
						token:this.token
					}
				}).then ((res) => {
					if(res.error_code==0){
						this.kaika=false;
					}else if(res.error_code==1000){
						this.kaika=true;
					}
				})
			}
		},
	}
</script>
